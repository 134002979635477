<template>
  <div
    class="container"
    v-title
    id="inspectResponseStatic"
    :data-title="$t('i18n.inspectResponseStatic')"
  >
    <div id="outer-title">{{ $t("i18n.inspectResponseStatic") }}</div>
    <el-date-picker
      v-model="dateRange"
      type="daterange"
      align="right"
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :shortcuts="shortcuts"
      @change="initData"
    ></el-date-picker>
    <el-select
      filterable
      clearable
      v-model="provinceIndex"
      @change="changeCityData"
      :placeholder="$t('i18n.province')"
    >
      <el-option
        v-for="(item, index) in provinceData"
        :key="index"
        :label="item.label"
        :value="index"
      ></el-option>
    </el-select>
    <el-select
      filterable
      clearable
      class="city"
      v-model="city"
      @change="initData"
      :placeholder="$t('i18n.city')"
    >
      <el-option
        v-for="(item, index) in cityData"
        :key="index"
        :label="item.label"
        :value="item.label"
      ></el-option>
    </el-select>
    <jl-chart name="option" class="chartCircle" :columns="pieOption"></jl-chart>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlChart from "../../components/chart";
export default {
  name: "ResponseStatic",
  components: {
    jlChart,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      shortcuts: [
        {
          text: "最近一周",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })(),
        },
        {
          text: "最近一个月",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })(),
        },
        {
          text: "最近三个月",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })(),
        },
      ],
      dateRange: "",
      province: "",
      city: "",
      provinceIndex: "",
      provinceData: proxy.$defined.getProvinceData(),
      cityData: [],
      pieOption: {
        title: t("i18n.inspectResponseStatic"),
        type: "pie",
        series: [
          {
            minAngle: 5, //最小的扇区角度（0 ~ 360），用于防止某个值过小导致扇区太小影响交互
            avoidLabelOverlap: true, //是否启用防止标签重叠策略
            radius: ["35%", "55%"],
            itemStyle: {
              normal: {
                label: {
                  formatter: "{b}:{d}%",
                },
              },
            },
            data: [],
          },
        ],
      },
    });

    const init = () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      state.dateRange = [start, end];
      initData();
    };

    const initData = async () => {
      let { data } = await proxy.$api.maintenance.getResponseStatus({
        beginTime: proxy.$defined.format(state.dateRange[0]) + " 00:00:00",
        endTime: proxy.$defined.format(state.dateRange[1]) + " 00:00:00",
        province: state.province,
        city: state.city,
      });
      state.pieOption.series[0].data = data;
    };

    const changeCityData = (index) => {
      state.city = "";
      state.cityData = state.provinceData[index].children;
      state.province = state.provinceData[index].label;
      initData();
    };

    init();

    return {
      ...toRefs(state),
      initData,
      changeCityData,
    };
  },
};
</script>

<style lang="scss">
#inspectResponseStatic {
  .el-date-editor {
    position: absolute;
    z-index: 2;
    top: 45px;
    left: 45px;
  }
  .el-select {
    position: absolute;
    z-index: 2;
    top: 95px;
    left: 45px;
    width: 145px;
  }
  .city {
    left: 200px;
  }
}
@media screen and (max-width: 768px) {
  #inspectResponseStatic {
    .el-date-editor {
      position: relative;
      top: 0;
      left: 10px;
      width: 300px;
    }
    .el-select {
      position: relative;
      top: 0;
      left: 0;
      margin: 10px 0 0 10px;
    }
  }
}
</style>
